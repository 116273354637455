import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './browserController.module.scss'
import { Unity, useUnityContext } from "react-unity-webgl";
import enlargeIcon from '../../assets/images/enlargeIcon.png'
import chromeImg from '../../assets/images/chrome.png'
import Logo from '../../components/logo';
import classNames from 'classnames';
import { FeatureToggleContext, LobbyContext } from '../../App';
import { isAndroid, isIOS } from 'react-device-detect';
import dayjs from 'dayjs';
import { getCookie, reportEvent } from '../../services/analytics.service';
import { useQuery } from '../../hooks/useQuery';
import { translations } from '../../i18n/config';
import { useNoSleep } from '../../hooks/useNoSleep';
import { debounce, getLanguage, getTranslation } from '../../utils/anyUtils';
import { APP_STORE_URL, CDN_URL, DEEP_LINK_ANDROID, PLAY_STORE_URL } from '../../consts/config';
import logoImg from '../../assets/images/logoImg.png';
import { postAnalytics, postLobbyLog } from '../../httpRequests/consoleRequests';
import NoSleep from 'nosleep.js';
import googlePlayIcon from '../../assets/images/googlePlayBadge.png';
import appStoreIcon from '../../assets/images/appStoreBanner.png';
const noSleep = new NoSleep();

function enableNoSleep() {
 noSleep.enable();
}

function BrowserControllerContainer({handle}: {handle: any}) {
  const ads = ["6219337", "6219226", "6219341", "6219343", "6219344", "6219345"]
  let adIndex = 0
  const featureToggles = useContext(FeatureToggleContext);
  const [showTapToFullscreen, setShowTapToFullScreen] = useState<boolean>(false)
  const downloadMessageTimeout = useRef<any>(null)
  const [offerDownloadAppMessage, setOfferDownloadAppMessage] = useState<null | {message: string, level: 'warning' | 'error'}>(null)
  const [showBanner, setShowBanner] = useState<boolean>(false)
  const [showInter, setShowInter] = useState<boolean>(false)
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false)
  const [fakePercent, setFakePercent] = useState<number>(0)
  const query = useQuery()
  const t = getTranslation("browserController")
  const language = getLanguage()
  let timesRequestedShowBanner = 0;
  let timeStarting = useRef(new Date)
  const rolloutStorage = localStorage.getItem('rollout')
  const adsStorage = localStorage.getItem('ads')
  const rollout = rolloutStorage == null ? 'production' : rolloutStorage
  const increaseFakePercent = () => {
    setFakePercent((fakePercent) => {
      if(fakePercent < 90) {
        return fakePercent + 1
      } else {
        return fakePercent
      }
    })
  }
  
  useNoSleep(true)

  useEffect(() => {
    const anyNav: any = navigator
    if ('wakeLock' in navigator) {
      anyNav["wakeLock"].request("screen").catch((e: any) => console.log("error locking " , e))
    }
    let fakePercentInterval : any
    fakePercentInterval  =  setInterval(increaseFakePercent, 100)
    //@ts-ignore
    const roomNumberQuery = query && query.roomNumber ? query.roomNumber : null
    if(isAndroid) {
      window.location.href = `${DEEP_LINK_ANDROID}?roomNumber=${roomNumberQuery}`;
    }
    if(roomNumberQuery) {
      reportEvent('player_scanned_qr_code')
    }
    if(handle && !handle.active) {
      askToFullScreen()
    }
    postAnalytics("player_started_downloading_bc", {referrer: document.referrer})
    window.addEventListener('focus', handleFocus)
    document.addEventListener('touchstart', enableNoSleep, false);

    return () => {
      document.removeEventListener('touchstart', enableNoSleep, false);

      clearInterval(fakePercentInterval)
      window.removeEventListener('focus', handleFocus)
    }
  }, [])
  useEffect(() => {
    if((isAndroid  && !!featureToggles.isAndroidMobileAppAvailble ) ||
    (isIOS  && !!featureToggles.isIosMobileAppAvailable as boolean )) {
      showOfferDownloadModal(t.recommandedExplain, 20000, 'warning')
    }
  },[isAndroid, isIOS,featureToggles])

  const showOfferDownloadModal = (message: string, expiry: number, level: 'warning' | 'error') => {
    setOfferDownloadAppMessage({ message, level })
    if(downloadMessageTimeout.current) {
      clearTimeout(downloadMessageTimeout.current)
    }
    downloadMessageTimeout.current = setTimeout(() => {
      closeOfferDownloadModal()
    }, expiry)
  }
  const closeOfferDownloadModal = () => {
    downloadMessageTimeout.current = null;
    setOfferDownloadAppMessage(null)
  }
  const localBrowser = localStorage.getItem("localGame") == "true"
  const { unityProvider, isLoaded, loadingProgression,addEventListener, removeEventListener } = useUnityContext({
    loaderUrl: localBrowser ?  `/webglControllerNew/Build/webglControllerNew.loader.js` : `${CDN_URL}/browserController/Build/browser.loader.js`,
    dataUrl: localBrowser ? `webglControllerNew/Build/webglControllerNew.data` :`${CDN_URL}/browserController/Build/browser.data`,
    frameworkUrl: localBrowser ? `/webglControllerNew/Build/webglControllerNew.framework.js` :  `${CDN_URL}/browserController/Build/browser.framework.js`,
    codeUrl:  localBrowser? `/webglControllerNew/Build/webglControllerNew.wasm` :  `${CDN_URL}/browserController/Build/browser.wasm`,
  });
  const handleGoHorizontal = ()=> {
   // screen.orientation.lock('horizontal')

  }
  const handleGoVertical = ()=> {
   //screen.orientation.lock('vertical')

  }
  const  adStatusCallback = (status: any) => {
    console.log('Ad Status: ' + status);
}

  const applixirOptions = {
    zoneId: featureToggles.applixirZoneId || 2050,
    userId: '7567',
    accountId: '7567',
    gameId: '8091',
    platform: 'Web',
    adStatusCb: adStatusCallback
};
  const handleShowInter = () => {
        //@ts-ignore
      //  window.invokeApplixirVideoUnit(applixirOptions)
    setShowInter(true)
  }
  const handleShowBanner = debounce( () => {

    setShowBanner(true)

  }, 50)
  const handleHideBanner = () => {
    setShowBanner(false)
  }
  const showAdAndHideBanner = () => {
      //  //@ts-ignore
      //  window.invokeApplixirVideoUnit(applixirOptions)
        handleHideBanner()
  }
  useEffect(() => {
    addEventListener("goHorizontal", handleGoHorizontal);
    addEventListener("goVertical", handleGoVertical);
    addEventListener("showBanner", handleShowBanner);
    addEventListener("hideBanner", handleHideBanner);
    addEventListener("showMobileOnly", handleMobileOnly);
    
    addEventListener("showInter", handleShowInter);

    return () => {
      removeEventListener("hideBanner", handleHideBanner);
      removeEventListener("showBanner", handleShowBanner);
      removeEventListener("showInter", handleShowInter);
      removeEventListener("showMobileOnly", handleMobileOnly);

      removeEventListener("goHorizontal", handleGoHorizontal);
      removeEventListener("goVertical", handleGoVertical);
    };
  }, [featureToggles, timeStarting]);


  useEffect(() => {
    if(isLoaded) {
      postAnalytics('player_finished_downloading_bc', {
        download_time_s: dayjs().diff(dayjs(timeStarting.current), "seconds")
      })
    }
  }, [isLoaded])

  const loadingPercentage = Math.round(loadingProgression * 100);
  useEffect(() => {
    if(isAndroid) {
      //@ts-ignore
      const roomNumberQuery = query && query.roomNumber ? query.roomNumber : null
      window.location.href = `${DEEP_LINK_ANDROID}?roomNumber=${roomNumberQuery}`;
    }
},[]);
const handleMobileOnly = () => {
  console.log("mobile only")
  showOfferDownloadModal(t.gameNotSupported, 60000, 'error')
}
const loadAd = (callback?: any) => {
  const adId = "//ophoacit.com/1?z=" + ads[adIndex % ads.length]
  const adZone = ads[adIndex % ads.length]
  const existingScript = document.getElementById(adId);
  if(existingScript) {
    existingScript.parentNode?.removeChild(existingScript)
  }
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = adId;
    script.id = adId;
    script.async = false
    document.body.appendChild(script);
    adIndex ++
    script.onload = () => { 
      setTimeout(( ) => {
        const existingAd = window.parent.document.getElementById("p_" +adZone.toString());
        console.log("existingAd", existingAd, adZone)
        const root = document.getElementById('browserController');
        //@ts-ignore
        if(existingAd && !!root) {
          //@ts-ignore
          root.appendChild(existingAd)
  
        }
      }, 1000)



      if (callback) callback();
    };
  }
};
  useEffect(() => {
    if(loadingPercentage === 90) {
      setTimeout( offerRefresh, 20000)
    }
  }, [loadingPercentage])
  const offerRefresh = () => {
    setShouldRefresh(true)
  }
  const handleFocus = () => {
    askToFullScreen()
  }
  const askToFullScreen = () => {
    if(process.env.NODE_ENV === 'development' ) {
      return
    }
    if(!isInFullScreen() && !isIOS) {
      setShowTapToFullScreen(true)
      setTimeout(() => {
        setShowTapToFullScreen(false)
      }, 5000)
    }
  }
  const handleFullScreenRequested = () => {

    if(!isIOS) {

      handle.enter()
      reportEvent('player_bc_reentered_fullscreen')
      setShowTapToFullScreen(false)
    }
  }
  const isInFullScreen = () => {
   return window.screen.width == window.innerWidth && window.screen.height == window.innerHeight
  }
  const handleRefresh = () => {
    window.location.reload();
    reportEvent('player_stuck_and_refreshed')
  }
  const showAd = () => {
    if(!!getCookie('cookieConsent')) {
              //@ts-ignore
      if(window&& window.adsbygoogle)
        //@ts-ignore
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }
  useEffect(() => {
    if(showInter) {
      setTimeout(showAd, 200)
    }

    return () => {
    }
  }, [showInter])
  useEffect(() => {
    if(showBanner) {
      showAd()
    }

    return () => {
    }
  }, [showBanner])

  const handlePlayInApp = (platform: string) => {
    reportEvent('player_clicked_download_app', {referrer: document.referrer, platform: platform})
    //@ts-ignore
    const roomNumberQuery = query && query.roomNumber ? query.roomNumber : null
    if(roomNumberQuery) {
      postLobbyLog(`Player clicked 'Download app' from ` + platform, roomNumberQuery)
    }
     if(platform === "android") {
      let appWindow = window.open(DEEP_LINK_ANDROID,"_blank");
      setTimeout( function () {if (appWindow) {
          appWindow.location =PLAY_STORE_URL;
              }
       },1000);
     }
     if(platform === "ios") {
      window.location.href = APP_STORE_URL;

     }
     
}
  return (
    <div id={"browserController"}className={ classes.wrapper}>
    { !isLoaded &&
       <div className={ classNames( classes.loaderWrapper ,{[classes.rtl]: language === "he"})}>
          <Logo/>
          <div className={ classes.explainer }> 
            {t.downloadingRemote} {loadingPercentage >= 90 ? loadingPercentage : fakePercent }%
          </div>
          {
            shouldRefresh && 
            <div className={ classes.refreshContainer }>
              <div className={ classes.row }>
                <div className={ classes.refresh }> 
                {t.stuckOn} 90%? 
                </div>
                <div onClick={ handleRefresh } className={ classes.refreshLink }> 
                {t.clickToRefresh}
                </div>  
              </div>
              <div className={ classes.row }>
                <div className={ classes.refresh }> 
                 {t.didntHelp}
                </div>
                <img src={chromeImg} className={ classes.chrome } />
              </div>
            </div>
          }

      </div>

    }

    {
      showTapToFullscreen &&
      <div className={classes.fullscreenContainer}>
        <div onClick={ handleFullScreenRequested } className={ classes.fullscreenCircle }>
          <img src={enlargeIcon} className={classes.fullscreenIcon} />
          <div className={classes.fullscreenExplainer}>
            
            {t.tapToFull}
          </div>
        </div>

      </div>
    }
    <Unity className={classes.unityWrapper} unityProvider={unityProvider} />

    {
      showInter && 
      <div className={classes.interContainer}>
              <ins className={classNames("adsbygoogle",classes.testInter)}
      data-ad-client="ca-pub-1189369488359090"
    data-ad-slot="7646531748">
      
    </ins>
    <div className={classes.x} onClick={() => setShowInter(false)}> X </div>
        </div>

    }

    {
      showBanner &&
      <ins className={classNames("adsbygoogle",classes.testBanner)}
      data-ad-client="ca-pub-1189369488359090"
      data-ad-slot="7086514148"
      ></ins>
    }
    {
      
        !!offerDownloadAppMessage &&
        <div className={classes.suggestAppBanner}>
           <div className={classes.description}>
              {t.recommanded}
          </div>
          <div className={classNames(classes.descriptionExplain,classes[offerDownloadAppMessage.level])}>
              {offerDownloadAppMessage.message}
          </div>
          <div className={classes.optionsContainer}>
          <a  onClick= {() =>  handlePlayInApp(isAndroid ? 'android' : 'ios') } className={ classes.startButton}>
             <img src={isAndroid ?  googlePlayIcon : appStoreIcon } className={classes.icon } /> 
          </a>
          <div className={classes.noThanksWrapper}>
            <div onClick={closeOfferDownloadModal} className={classes.noThanks}>
              {t.noThanks}
            </div>
          </div>
            </div>

        </div>
    }
    {
      false &&
      <div className={classes.supportWrapper}>
        <button className={classes.supportButton} onClick={showAdAndHideBanner} type="button" id="showRewardAdButton">
          {
            t.viewAd
          }
        </button>
        <div className={classes.supportMessage}>
          {
            t.supportMessage
          }
        </div>
        <div className={classNames(classes.supportMessage, classes.marked)}>
          {
            t.free
          }
        </div>
        <img className={classes.supportLogo} src={logoImg} />

      </div>
      

    }
    </div>
    
  );
}

export default BrowserControllerContainer;
