import en from './locales/en/translation.json'
import he from './locales/he/translation.json'
import es from './locales/es/translation.json'
import tr from './locales/tr/translation.json'
import pt from './locales/pt/translation.json'
import fr from './locales/fr/translation.json'
import id from './locales/id/translation.json'

export const translations = {
  en,
  he,
  es,
  pt,
  tr,
  fr,
  id
}