import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './gameTopLayer.module.scss'
import { LobbyContext } from '../../App';
import enlargeIcon from '../../assets/images/enlargeIcon.png'
import minifyIcon from '../../assets/images/minifyIcon.png'
import logoImg from '../../assets/images/logoCropped.png';
import israelflag from '../../assets/images/israelflag.png';
import AddPeopleExplainer from '../addPeopleExplainer';
import socketService from '../../services/socket.service';
import classNames from 'classnames';
import { getCountryByLanguage, getLanguage, getLanguageNameByLanguageCode, getTranslation, supportedLanguages } from '../../utils/anyUtils';
import useOutsideClick from '../../hooks/useClickOutside';
import { postAnalytics } from '../../httpRequests/consoleRequests';

interface IProps {
  className?: string
  handle?: any
  gameMode?: boolean
}
enum EPingType {
  good = 'good',
  medium = 'medium',
  bad = 'bad',
  notPlayable = 'notPlayable'
}
function GameTopLayer({ handle, gameMode }: IProps) {
  const lobbyContext = useContext(LobbyContext);
  const [languageMenuShown, setLanguageMenuShown] = useState(false)
  const [ping,setPing] = useState(0)
  let menuRef = useRef(null)
  const [isViewingQR, setIsViewingQR ] = useState(false)
  const language = getLanguage()
  useOutsideClick(menuRef, () => setLanguageMenuShown(false))
  //@ts-ignore
  const fontSizeMap = {
    0: 14,
    5: 11,
    8: 8,
  }
  const preloadImage = (src: string) => {
    return (new Promise((resolve, reject) => {
      const image = new Image()
      image.onload = resolve
      image.onerror = reject
      image.src = src
    }))
  }
  useEffect(() => {
    supportedLanguages.forEach(lang => {
      preloadImage(getLanguageImage(lang))
    })

    checkPing()
    setInterval(checkPing, 5000)

    socketService.socket.on("addPlayersRequested", handleAddPlayer)
    return () => {
      socketService.socket.off("addPlayersRequested", handleAddPlayer)
    }
  }, [])

  const checkPing = () => {
    const start = Date.now();

    socketService.socket.emit("ping", () => {
      const duration = Date.now() - start;
      setPing(duration)
    });
  }
  const handleAddPlayer = () => {
    setIsViewingQR(is => !is)
  }
  const getFontSize =(text: string, map: object) : number =>   {
    let fontSize = 11
    Object.entries(map).forEach(([key, value]) =>{
      if(parseInt(key) <= text.length) {
        fontSize = value
      
      }
    })
    return fontSize;
  }
  const rgbaAsString = ({r,g,b,a}: {r:number, g: number, b: number, a: number}) => {
    return `rgba(${r},${g},${b},${a})`
  }
  const handleEnlargeOrMinifiy = () => {
    if(handle.active) {
      handle.exit()
    } else {
      handle.enter()
    }
  }

  const handleViewQrBig = () => {
    setIsViewingQR(true)
  }
  const handleSetCloseAddPeople = () => {
    setIsViewingQR(false)
  }
  const getPingType = () => {
    if(ping < 60) {
      return EPingType.good
    }
    if(ping < 100) {
      return EPingType.medium
    }
    if(ping < 130) {
      return EPingType.bad
    }
    return EPingType.notPlayable
  }
  const getLanguageImage = (language: string) => {
    return  `https://flagsapi.com/${getCountryByLanguage(language)}/shiny/64.png`
  }
  const pingType = getPingType()
  const from = localStorage.getItem("from")
  const transformedFrom = from ? from.replace("_qr_scan", "") : from 
  const toggleLanguageMenu = () => {
    setLanguageMenuShown(!languageMenuShown)
  }
  const handleLanguageSelected = async (lang: string) => {
    setLanguageMenuShown(false) 
    const currentLang = localStorage.getItem('lang')
    if(lang === currentLang) {
      return
    }
    localStorage.setItem('lang', lang)
    await postAnalytics("language_changed", {lang, prevLang: currentLang})

    window.location.reload();
  }
  const t = getTranslation('topLayer')
  console.log(t)

  const qrUrl = window.location.origin  + "/download?roomNumber=" + lobbyContext.roomNumber + "&from=" + transformedFrom + "_qr_scan" + "&language=" + language
  return (
    <div className={ classNames(classes.topLayer, {[classes.playMode]: handle.active && gameMode}) }>
      {
        lobbyContext && lobbyContext.roomNumber && isViewingQR &&
        <AddPeopleExplainer
          onClose={ handleSetCloseAddPeople }
          qrUrl={ qrUrl }
          roomNumber={lobbyContext.roomNumber!}
          />
      }
      <div className={classNames(classes.pingContainer, classes[pingType?.toString()])}>
        ping : {ping}
      </div>
      <div className={ classes.titleContainer }>
          <img src={ logoImg } className={ classes.logoImg } />
          <div className={ classes.title }>
              PlayCloud
          </div>
      </div>

      <div className={ classes.players }>
        <div className={classes.languageWrapper}>
          <div onClick={toggleLanguageMenu} className={ classes.language }>
            <div className={classes.languageTitle}>
              {t['language']}
            </div>
            <img src={getLanguageImage(localStorage.getItem('lang')!)} className={classes.flag}/>
          </div>
          {
              languageMenuShown && 
              <div ref={menuRef} className={classes.flagMenu}>
              {
                supportedLanguages.map(languageSupported => (
                  <div onClick={() => handleLanguageSelected(languageSupported)} className={classes.languageRowWrapper}>
                    <div className={classes.languageRow} >
                        <img src= {getLanguageImage(languageSupported)} className={classes.flag}/>

                        <div className={classes.languageName}>
                          {getLanguageNameByLanguageCode(languageSupported)}
                        </div>
                      </div>
                  </div>
                ))
              }
            </div>
            }
        </div>

        <div  onClick={ handleViewQrBig } className={ classes.addPlayersContainer}>
          <div className={ classes.plus }>
            +
          </div>
          <div className={ classes.addPlayers }>
            {t.addPlayers}
          </div>
        </div>

      {
        lobbyContext && lobbyContext.players && lobbyContext.players.filter(player => !!player).map((player,index) => 
        <div style={{borderColor: player.color ? rgbaAsString(player.color) : undefined}} className={ classes.player }>
          <div style={{fontSize: getFontSize(player.name, fontSizeMap)}} className={ classes.playerText }>
            {player.name.substring(0, 20)}
          </div>
        </div>)
      }
    </div>
    { handle && <img src={ handle.active ? minifyIcon : enlargeIcon } onClick={ handleEnlargeOrMinifiy } className={classes.enlargeIcon} /> }
    {
      lobbyContext.roomNumber &&
      <div className={ classes.roomData }>
       <div className={ classes.roomNumber }>
       {lobbyContext.roomNumber}
      </div>
        
    </div>
    }
  </div>
  );
}

export default GameTopLayer;
