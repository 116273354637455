import { translations } from '../i18n/config';

export const isIOS = ()  => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
  export const getLanguageNameByLanguageCode = (lang: string) => {
    switch(lang) {
      case 'he':
        return 'עברית'
      case 'en':
        return 'English'
      case 'fr':
        return 'Français'
      case 'id':
        return 'Indonesian'
      case 'pt':
          return 'Português'
      case 'es':
        return 'Español'
      case 'tr':
        return 'Türkçe'
      default: 
        return 'English'
    }
  }
export const getCountryByLanguage = (lang: string) => {
    switch(lang) {
      case 'he':
        return 'IL'
      case 'en':
        return 'GB'
      case 'fr':
        return 'FR'
      case 'id':
        return 'ID'
      case 'pt':
          return 'PT'
      case 'es':
        return 'ES'
      case 'tr':
        return 'TR'
      default: 
        return 'GB'
    }
  }
  export const getTranslation = (context: string) => {
    const language = getLanguage()
    localStorage.setItem("lang", language!)
    //@ts-ignore
    const translation = translations[language] ? translations[language] : translations['en'];
    //@ts-ignore
    const t = translation[context]
    return t
  }
  export const supportedLanguages = ['en', 'es','he','pt', 'tr', 'fr', 'id']

  export const getLanguage = () => {
    let language = navigator.language.split("-")[0] || "en"
    if(!supportedLanguages.includes(language)) {
      language = "en"
    }
    const languageSelected = localStorage.getItem("lang") === null ?  language : localStorage.getItem("lang")
    return languageSelected
  }
  export function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number,
  ): (...args: Params) => void {
    let timer: NodeJS.Timeout
    return (...args: Params) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func(...args)
      }, timeout)
    }
  }