import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './downloadController.module.scss'
import { Unity, useUnityContext } from "react-unity-webgl";
import controllerGif from '../../assets/images/controllerGif.gif'
import chromeImg from '../../assets/images/chrome.png'
import Logo from '../../components/logo';
import classNames from 'classnames';
import { FeatureToggleContext, LobbyContext } from '../../App';
import dayjs from 'dayjs';
import { reportEvent } from '../../services/analytics.service';
import { useQuery } from '../../hooks/useQuery';
import { translations } from '../../i18n/config';
import { useNoSleep } from '../../hooks/useNoSleep';
import { debounce, getLanguage, getTranslation } from '../../utils/anyUtils';
import { APP_STORE_URL, CDN_URL, DEEP_LINK_ANDROID, PLAY_STORE_URL } from '../../consts/config';
import logoImg from '../../assets/images/logoImg.png';
import { postAnalytics, postLobbyLog } from '../../httpRequests/consoleRequests';
import NoSleep from 'nosleep.js';
import googlePlayIcon from '../../assets/images/googlePlayIcon2.png';
import appStoreIcon from '../../assets/images/appStoreIcon.png';
import { BrowserView, isIOS, isAndroid, isMobile, MobileView, isBrowser } from 'react-device-detect';


function DownloadControllerContainer({handle}: {handle: any}) {
  const featureToggles = useContext(FeatureToggleContext);
  const query = useQuery()
  const t = getTranslation("downloadController")
  const language = getLanguage()
  let timeStarting = useRef(new Date)

  const handlePlayInApp = () => {
    reportEvent('player_clicked_download_app', {referrer: document.referrer, platform: 'android'})
    //@ts-ignore
    const roomNumberQuery = query && query.roomNumber ? query.roomNumber : null
    if(roomNumberQuery) {
      postLobbyLog(`Player clicked 'Download app' from android`, roomNumberQuery)
    }
    let appWindow = window.open(DEEP_LINK_ANDROID,"_blank");
    setTimeout( function () {if (appWindow) {
        appWindow.location =PLAY_STORE_URL;
            }
     },1000);
}
const handlePlayInAppIos= () => { 
  reportEvent('player_clicked_download_app', {referrer: document.referrer, platform: 'ios'})

        //@ts-ignore
        const roomNumberQuery = query && query.roomNumber ? query.roomNumber : null
        if(roomNumberQuery) {
          postLobbyLog(`Player clicked 'Download app' from ios`, roomNumberQuery)
        }
        window.location.href = APP_STORE_URL
        /*
    let appWindow = window.open(DEEP_LINK_ANDROID,"_blank");
    setTimeout( function () {if (appWindow) {
        appWindow.location =APP_STORE_URL;
            }
     },1000);
     
     window.open(APP_STORE_URL,"_blank");
     */

}
const handleClickedAlreadyHave = () => {
  reportEvent('player_clicked_already_have', {referrer: document.referrer, platform: isIOS ? 'ios' : 'android'})
      //@ts-ignore
      const roomNumberQuery = query && query.roomNumber ? query.roomNumber : null
      if(roomNumberQuery) {
        postLobbyLog(`Player clicked 'Already have' ${isIOS ? 'ios' : 'android'}`, roomNumberQuery)
      }
}

  useEffect(() => {
    if(!featureToggles) return
    //@ts-ignore
    const roomNumberQuery = query && query.roomNumber ? query.roomNumber : null
        //@ts-ignore
    const host = query && query.host ? query.host : null
    /*
    if(isIOS) {
      window.location.href = window.location.origin + "/browser?roomNumber=" + roomNumberQuery
    }
    */
   const browserUrl = window.location.origin + "/browser?roomNumber=" + roomNumberQuery
    if(isAndroid) {

      console.log("featureToggles.isAndroidMobileAppAvailble", featureToggles)
      if(featureToggles.isAndroidMobileAppAvailble == false) {
        window.location.href = browserUrl
      }
      
      window.location.href = `${DEEP_LINK_ANDROID}?roomNumber=${roomNumberQuery}`;
    }
    if(isIOS) {
      if(featureToggles.isIosMobileAppAvailable == false) {
        window.location.href = browserUrl
      } 
    //  window.location.href = `${DEEP_LINK_ANDROID}?roomNumber=${roomNumberQuery}`;
    }
    if(roomNumberQuery) {
  //    postLobbyLog(`Player scanned QR code from ${isIOS ? 'ios' : 'android'}`, roomNumberQuery)
      reportEvent('player_scanned_qr_code', {referrer: document.referrer})
    }

    return () => {
    }
  }, [featureToggles])

  const handlePlayBrowser = () => {
      //@ts-ignore
      const roomNumberQuery = query && query.roomNumber ? query.roomNumber : null
    window.location.href = window.location.origin + "/browser?roomNumber=" + roomNumberQuery
  }
  useEffect(() => {
    if(Object.keys(featureToggles).length === 0 )return
    //@ts-ignore
    const roomNumberQuery = query && query.roomNumber ? query.roomNumber : null
    if(roomNumberQuery) {
      postLobbyLog(`Player scanned QR code from ${isIOS ? 'ios' : 'android'}`, roomNumberQuery)
    }
    return () => {
    }
  }, [featureToggles])
  return (
    <div id={"browserController"}className={ classes.wrapper}>
            <div className={classes.topper}>
            <img src={ logoImg } className={classes.logoImg } />
            <div className={classes.text}>PlayCloud</div>
          </div>
              <div className={classes.text2}> {t.explain}</div>
              <img className={classes.gif} src={controllerGif} />
              <img src={ logoImg } className={classes.logoImg2 } />
              {
                  isIOS &&  <a href={APP_STORE_URL} onClick={handleClickedAlreadyHave} className={ classes.startButtonInvert}>
                  {t.alreadyHave}
              </a>
              }
              {
            isAndroid    && 
            <a href={DEEP_LINK_ANDROID} onClick={handleClickedAlreadyHave}  className={ classes.startButtonInvert}>
              {t.alreadyHave}
          </a>
        }
          <div className={classes.dontHave}>{t.dontHave}</div>

          {
            isAndroid  && featureToggles.isAndroidMobileAppAvailble  && 
            <a href={DEEP_LINK_ANDROID} onClick= { handlePlayInApp } className={ classes.startButton}>
             <img src={ googlePlayIcon } className={classes.googlePlayIcon} /> {t.downloadIt}
          </a>
        }
        {
            isIOS&& featureToggles.isIosMobileAppAvailable &&  <a href={APP_STORE_URL} onClick= { handlePlayInAppIos } className={ classes.startButton}>
            <img src={ appStoreIcon } className={classes.googlePlayIcon} /> {t.downloadIt}
        </a>
        }
                  
        {
            (
              isAndroid  && featureToggles.isAbleToPlayBrowserAndroid 
              ||
              isIOS  && featureToggles.isAbleToPlayBrowserIos ) && 
            <div onClick={handlePlayBrowser}  className={ classes.startButtonInvert}>
              {t.playInBrowser}
          </div>
        }

    </div>
    
  );
}

export default DownloadControllerContainer;
